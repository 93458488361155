import { HStack } from '@chakra-ui/react'
import { TimeUnit } from 'components/atoms/TimeUnit'
import EthersContext from 'contexts/EtherInstances'
import { useTimer } from 'hooks/useTimer'
import { useContext, useEffect } from 'react'

const expiresInSeconds = 1670193046770

export const Timer = () => {
  const { provider } = useContext(EthersContext)
  const { seconds, minutes, hours, days, setNewExpire } = useTimer({
    expiresInSeconds,
  })

  useEffect(() => {
    async function init() {
      const currentBlockNumber = await provider.getBlockNumber()
      setNewExpire(
        new Date().getTime() + (16115728 - currentBlockNumber) * 12 * 1000
      )
    }
    init()
  }, [provider, setNewExpire])

  return (
    <HStack spacing="4">
      <TimeUnit value={days} label="Days" />
      <TimeUnit value={hours} label="Hours" />
      <TimeUnit value={minutes} label="Minutes" />
      <TimeUnit value={seconds} label="Seconds" />
    </HStack>
  )
}
