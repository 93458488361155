import { extendTheme, ThemeConfig } from '@chakra-ui/react'

const config: ThemeConfig = {
  initialColorMode: 'light',
  useSystemColorMode: false,
}
const theme = extendTheme({
  config,
  colors: {
    brand: {
      900: '#ffa1a1',
      800: '#fb8f99',
      700: '#f67c90',
      600: '#ec567f',
      500: '#e3306e',
      400: '#ed90a5',
      300: '#cc0a4a',
      200: '#c60a41',
      100: '#bf0a37',
      50: '#ffd6e0',
    },
  },
})

export default theme
