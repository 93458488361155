import { useContext, useEffect, useState } from 'react'
import EthersContext from 'contexts/EtherInstances'
import { Event } from 'ethers'
import {
  Box,
  Button,
  Heading,
  Link,
  SimpleGrid,
  Text,
  useColorModeValue as mode,
} from '@chakra-ui/react'
import { AiOutlineBlock } from 'react-icons/ai'
import { BsArrowRight } from 'react-icons/bs'
import { FaDonate } from 'react-icons/fa'
import { Activity } from './Activity'
import { ETHERSCAN_URL, TRADABLE_CORE_ADDRESS } from 'base/dotenv'
import { Block } from '@ethersproject/providers'

interface TradeList extends Event {
  timestamp: number
}

type ActivityBlock = { listIndex: number } & Block

export const Activities = () => {
  const [tradeList, setTradeList] = useState<TradeList[]>([])
  const { tradableContractRead, provider } = useContext(EthersContext)
  useEffect(() => {
    async function fetchContractEvents() {
      const fromBlock = (await provider.getBlockNumber()) - 10000000000
      const listTrade = await tradableContractRead.queryFilter(
        tradableContractRead.filters.Trade(),
        fromBlock,
        'latest'
      )
      const listDeposit = await tradableContractRead.queryFilter(
        tradableContractRead.filters.Deposit(),
        fromBlock,
        'latest'
      )
      let newList = [...listTrade, ...listDeposit]
      const promises = newList.reduce(
        (
          prev: Promise<ActivityBlock | string>[],
          tx: Event,
          index
        ): Promise<ActivityBlock | string>[] => [
          ...prev,
          tx.getBlock().then((result) => ({ ...result, listIndex: index })),
          tx.getTransaction().then((result) => result.from),
        ],
        []
      )

      const promisesResolved = await Promise.all(promises)
      newList = promisesResolved
        .reduce((prev: TradeList[], promise: ActivityBlock | string) => {
          if (typeof promise !== 'string') {
            return [
              ...prev,
              {
                ...newList[promise.listIndex],
                timestamp: promise.timestamp,
              },
            ]
          }
          const lastPromise = { ...prev[prev.length - 1], address: promise }
          return [...prev.slice(0, prev.length - 1), lastPromise]
        }, [])
        .sort((a, b) => (a.timestamp > b.timestamp ? -1 : 1))
      setTradeList(newList.slice(-10) as TradeList[])
    }
    fetchContractEvents()
  }, [provider, tradableContractRead])

  return (
    <Box
      as="section"
      py={{ md: '12' }}
      id="activities"
      bg={mode('gray.50', 'gray.800')}
    >
      <Box
        maxW={{ base: '100%', lg: '7xl' }}
        mx="auto"
        px={{ base: '6', md: '12', lg: '20' }}
        py={{ base: '12', md: '20' }}
        display={'flex'}
        flexWrap={'wrap-reverse'}
        justifyContent={'space-between'}
      >
        <Box w={{ base: '100%', md: '45%' }}>
          <Heading
            size="xl"
            mb="4"
            fontWeight="extrabold"
            display={{ base: 'none', md: 'block' }}
          >
            Activities
          </Heading>
          <Text
            fontSize={{ md: 'lg' }}
            mb="6"
            maxW="md"
            color={mode('gray.600', 'gray.400')}
          >
            You can see the last activities on the right box or access the Smart
            Contract to see all activities and more!
          </Text>
          <Button
            size="lg"
            colorScheme="brand"
            rightIcon={<BsArrowRight />}
            fontWeight="bold"
            fontSize="md"
            w={{ base: 'full', sm: 'auto' }}
            as={Link}
            href={`${ETHERSCAN_URL}address/${TRADABLE_CORE_ADDRESS}`}
            target={'_blank'}
          >
            Check out on Etherscan
          </Button>
        </Box>

        <Box w={{ base: '100%', md: '45%' }} mb={{ base: 8, md: 0 }}>
          <Heading
            size="xl"
            mb="4"
            fontWeight="extrabold"
            display={{ base: 'block', md: 'none' }}
          >
            Activities
          </Heading>
          <SimpleGrid columns={1} spacing={7} maxH="320px" overflowY={'scroll'}>
            {tradeList.length > 0 &&
              tradeList.map((tx) => {
                const address = `${tx.address.substring(
                  0,
                  5
                )}...${tx.address.substring(tx.address.length - 5)}`

                return (
                  <Activity
                    key={tx.blockNumber}
                    icon={tx.event === 'Deposit' ? FaDonate : AiOutlineBlock}
                    title={tx.event ?? 'Deposit'}
                    blocks={tx.args?.quantity?.toNumber()}
                    address={address}
                    txId={tx.transactionHash}
                    timestamp={tx.timestamp}
                  />
                )
              })}
          </SimpleGrid>
        </Box>
      </Box>
    </Box>
  )
}
