import {
  MouseEventHandler,
  RefObject,
  useCallback,
  useContext,
  useEffect,
} from 'react'
import {
  Box,
  Button,
  Collapse,
  Heading,
  Portal,
  Stack,
  StackItem,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { BlocksList } from 'components/molecules/BlockList'
import { CartDrawer } from '../CartDrawer'
import { useAppDispatch, useAppSelector } from 'hooks/redux'
import { WalletIsConnected } from '../WalletIsConnected'
import { depositSlice } from 'features/blocks/depositSlice'
import { STATUS } from 'utils/status'
import { fetchTransferToVault } from 'services/blocks'
import EthersContext from 'contexts/EtherInstances'
import { HStackItemList } from 'components/atoms/HStackItemList'
import { useRefreshBlocks } from 'hooks/useRefreshBlocks'
import { useToastUpdate } from 'hooks/useToastUpdate'
import { myBlocksSlice } from 'features/blocks/myBlocksSlice'

const BLOCKS_SELECTED_LIMIT = 25

interface DepositBlocksProps {
  isMoveApproved: Boolean
  onMoveApproval: MouseEventHandler<HTMLButtonElement>
  navRef: RefObject<HTMLElement | null> | undefined | null
}

export const DepositBlocks = ({
  isMoveApproved,
  onMoveApproval,
  navRef,
}: DepositBlocksProps) => {
  useRefreshBlocks(false)
  useToastUpdate()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { tradableContractSigned } = useContext(EthersContext)
  const blocks = useAppSelector((state) => state.myBlocks.list)
  const selectedBlocks = useAppSelector<number[]>(
    (state) => state.deposit.ids as number[]
  )
  const price = useAppSelector((state) => state.deposit.price)
  const depositStatus = useAppSelector<STATUS>((state) => state.deposit.status)
  const isAllowedToMove = useAppSelector(
    (state) => state.myBlocks.isAllowedToMove
  )
  const dispatch = useAppDispatch()
  const toast = useToast()

  const onSelect = useCallback(
    (id) => () => {
      if (selectedBlocks.includes(id)) {
        dispatch(depositSlice.actions.remove(id))
        return
      }
      if (selectedBlocks.length >= BLOCKS_SELECTED_LIMIT) return
      dispatch(depositSlice.actions.add({ id }))
    },
    [dispatch, selectedBlocks]
  )

  const onDepositConfirm = useCallback(
    (donation: number) => () => {
      dispatch(
        fetchTransferToVault({
          contract: tradableContractSigned,
          blocks: selectedBlocks,
          donation,
        })
      )
    },
    [dispatch, selectedBlocks, tradableContractSigned]
  )

  useEffect(() => {
    if (isOpen && selectedBlocks.length < 2) {
      onClose()
    }
  }, [isOpen, onClose, selectedBlocks.length])

  useEffect(() => {
    if (selectedBlocks.length < 2) return
    if (depositStatus === STATUS.SUCCESS) {
      toast({
        title: 'Deposit approved',
        description:
          'As soon as your transaction is confirmed, you start making a profit from new transactions.',
        duration: 10000,
        isClosable: true,
      })
      onClose()
      dispatch(depositSlice.actions.clear())
      return
    }
    if (depositStatus === STATUS.PENDING) {
      toast({
        title: 'Deposit',
        description: 'Approve the transaction on metamask',
        status: 'info',
        duration: 3000,
        isClosable: true,
      })
      return
    }
    if (depositStatus === STATUS.ERROR) {
      toast({
        title: 'Deposit',
        description: 'A error happened. Check your wallet and try again.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
      dispatch(depositSlice.actions.clearError())
    }
  }, [depositStatus, dispatch, onClose, selectedBlocks.length, toast])

  useEffect(() => {
    return () => {
      dispatch(depositSlice.actions.clear())
      dispatch(myBlocksSlice.actions.clear())
    }
  }, [dispatch])

  return (
    <>
      {isMoveApproved && !isAllowedToMove && (
        <Stack py={10} px={8} spacing={10}>
          <StackItem textAlign={'center'}>
            <Heading size={'lg'}>First time depositing?</Heading>
          </StackItem>
          <StackItem textAlign={'center'}>
            <WalletIsConnected>
              <Text pb="6" fontSize={'lg'} color={'brand.500'}>
                Waiting for your transaction to be confirmed. If it was already
                confirmed, refresh this page.
              </Text>
            </WalletIsConnected>
          </StackItem>
        </Stack>
      )}
      {!isAllowedToMove && !isMoveApproved && (
        <Stack py={10} px={8} spacing={10}>
          <StackItem textAlign={'center'}>
            <Heading size={'lg'}>First time depositing?</Heading>
          </StackItem>
          <StackItem textAlign={'center'}>
            <WalletIsConnected>
              <Text pb="6">
                You must permit the contract to move your Blocks when
                depositing. (No Blocks will be moved in this step)
              </Text>
              <Button
                colorScheme={'brand'}
                variant={'outline'}
                onClick={onMoveApproval}
              >
                Set approval for the Contract
              </Button>
              <Text
                pt="2"
                fontSize={'sm'}
                fontStyle={'italic'}
                color={'brand.300'}
              >
                If you have already confirmed the Set Approval tx, wait and
                <br />
                refresh the page when the tx is completed.
              </Text>
            </WalletIsConnected>
          </StackItem>
        </Stack>
      )}
      {isAllowedToMove && navRef && (
        <>
          <Stack py={10} px={8} spacing={10}>
            <StackItem textAlign={'center'}>
              <Heading size={'lg'}>
                Select the Blocks you want to deposit
              </Heading>
            </StackItem>
            <StackItem>
              <BlocksList
                blocks={blocks}
                onSelectBlock={onSelect}
                selectedBlocks={selectedBlocks}
              />
              <Portal containerRef={navRef}>
                <Collapse in={selectedBlocks.length > 0} animateOpacity>
                  <Box bgColor={'blackAlpha.900'} py={4}>
                    <Box
                      maxW={'7xl'}
                      mx="auto"
                      textAlign={'right'}
                      px={{ base: '6', md: '8' }}
                      display={'flex'}
                      alignItems={'center'}
                      justifyContent={'space-between'}
                    >
                      <Text
                        textColor={
                          selectedBlocks.length < BLOCKS_SELECTED_LIMIT
                            ? 'white'
                            : 'brand.500'
                        }
                      >
                        {selectedBlocks.length}/{BLOCKS_SELECTED_LIMIT} Selected
                        Blocks{' '}
                        {selectedBlocks.length < 2 && (
                          <Text as={'span'} textColor={'brand.500'}>
                            (Must select at least 2 Blocks)
                          </Text>
                        )}
                      </Text>
                      <Button
                        ml={4}
                        onClick={onOpen}
                        disabled={selectedBlocks.length < 2}
                      >
                        Next
                      </Button>
                    </Box>
                  </Box>
                </Collapse>
              </Portal>
            </StackItem>
          </Stack>
          <CartDrawer
            blocksUser={selectedBlocks}
            onClose={onClose}
            onRemove={onSelect}
            isOpened={isOpen}
            onConfirm={onDepositConfirm}
            status={depositStatus}
            title={`Depositing ${selectedBlocks.length} Blocks`}
            feeText={`1 Block + ${price}Ξ`}
            dialogText={
              <>
                <HStackItemList>
                  Blocks deposited will be moved to the TB Vault. So you won't
                  be able to use or see them in your wallet.
                </HStackItemList>
                <HStackItemList>
                  You can withdraw any of the Blocks available from TB Vault at
                  the withdrawal moment. Therefore, your deposited Blocks may
                  have gone.
                </HStackItemList>
                <HStackItemList>
                  You agree you are depositing {selectedBlocks.length - 1}{' '}
                  Blocks, as 1 of the selected Blocks will be used to pay other
                  depositors.
                </HStackItemList>
              </>
            }
          />
        </>
      )}
    </>
  )
}
