import EthersContext from 'contexts/EtherInstances'
import { useCallback, useContext, useEffect } from 'react'
import {
  fetchIsAllowedToMove,
  fetchBlocks,
  fetchMoveApproval,
} from 'services/blocks'
import { useAppDispatch, useAppSelector } from './redux'

export const useWallet = () => {
  const {
    blocksContractSigned: contract,
    blocksContractRead,
    userAddress,
    walletIsConnected,
  } = useContext(EthersContext)
  const dispatch = useAppDispatch()
  const moveIsApproved = useAppSelector(
    (state) => state.myBlocks.isAllowedToMove
  )
  const onMoveApproval = useCallback(
    () =>
      dispatch(
        fetchMoveApproval({
          contract,
          userAddress,
        })
      ),
    [contract, dispatch, userAddress]
  )

  useEffect(() => {
    if (!walletIsConnected) return
    dispatch(
      fetchIsAllowedToMove({
        contract: blocksContractRead,
        userAddress,
      })
    )
  }, [blocksContractRead, dispatch, userAddress, walletIsConnected])

  useEffect(() => {
    if (!walletIsConnected && !moveIsApproved) return

    dispatch(
      fetchBlocks({
        contract,
        userAddress,
      })
    )
  }, [contract, dispatch, moveIsApproved, userAddress, walletIsConnected])

  return {
    onMoveApproval,
    moveIsApproved,
  }
}
