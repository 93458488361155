import { BigNumber, ethers } from 'ethers'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { TRADABLE_CORE_ADDRESS } from 'base/dotenv'
import { RootState } from 'store'
import { STATUS } from 'utils/status'

interface BaseFetchProps {
  contract: ethers.Contract | null
  userAddress: string
}

const fetchBlocksCall = async ({ contract, userAddress }: BaseFetchProps) => {
  if (!userAddress || !contract) return []
  try {
    const getTokenPromises = []
    const totalBlocks = parseInt(await contract.balanceOf(userAddress), 10)
    for (let i = totalBlocks - 1; i >= 0; i--) {
      getTokenPromises.push(
        contract
          .tokenOfOwnerByIndex(userAddress, i)
          .then((token: string) => parseInt(token, 10))
      )
    }
    const tokens = await Promise.all(getTokenPromises)

    return tokens
  } catch (err) {
    console.log('fetchUserBlocksTokens error: ', err)
    return []
  }
}

export const getBlocksTotal = async ({
  contract,
  userAddress,
}: BaseFetchProps) => {
  if (!userAddress || !contract) return '-'
  try {
    const totalBlocks = (await contract.balanceOf(userAddress)) as BigNumber

    return totalBlocks.toString()
  } catch (err) {
    console.log('getBlocksTotal error: ', err)
    return '-'
  }
}

export const fetchBlocks = createAsyncThunk(
  'blocks/fetchBlocksStatus',
  fetchBlocksCall,
  {
    condition: (_, { getState }) => {
      const { myBlocks } = getState() as RootState
      if (myBlocks.loading === STATUS.PENDING) {
        return false
      }
    },
  }
)

export const fetchVaultBlocks = createAsyncThunk(
  'blocks/fetchVaultBlocksStatus',
  fetchBlocksCall
)

export const fetchMoveApproval = createAsyncThunk(
  'blocks/fetchMoveApprovalStatus',
  async (
    { contract: blockContract, userAddress }: BaseFetchProps,
    thunkAPI
  ) => {
    if (!userAddress || !blockContract) return false
    try {
      const tx = await blockContract.setApprovalForAll(
        TRADABLE_CORE_ADDRESS,
        true
      )
      tx.wait().then(() => {
        thunkAPI.dispatch(
          fetchIsAllowedToMove({
            contract: blockContract,
            userAddress,
          })
        )
      })
      thunkAPI.dispatch(
        fetchBlocks({
          contract: blockContract,
          userAddress,
        })
      )
      return true
    } catch (err) {
      console.log('setApprovalForAll error: ', err)
      throw thunkAPI.rejectWithValue('setApprovalForAll didnt work')
    }
  }
)

export const fetchIsAllowedToMove = createAsyncThunk(
  'blocks/fetchIsAllowedToMoveStatus',
  async (
    { contract: blockContract, userAddress }: BaseFetchProps,
    thunkAPI
  ) => {
    if (!userAddress || !blockContract) return false
    try {
      const tx = await blockContract.isApprovedForAll(
        userAddress,
        TRADABLE_CORE_ADDRESS
      )
      return tx
    } catch (err) {
      console.log('isApprovedForAll error: ', err)
      throw thunkAPI.rejectWithValue('isApprovedForAll didnt work')
    }
  }
)

interface fetchTransferToVaultProps
  extends Omit<BaseFetchProps, 'userAddress'> {
  blocks: number[]
  donation: number
}

export const fetchTransferToVault = createAsyncThunk(
  'blocks/fetchTransferToVaultStatus',
  async (
    { contract: tradableContract, blocks, donation }: fetchTransferToVaultProps,
    thunkAPI
  ) => {
    if (!tradableContract) return false
    const { deposit } = thunkAPI.getState() as RootState
    const price = deposit.price
    try {
      await tradableContract.deposit(blocks, {
        value: ethers.utils.parseEther((price + donation).toString()),
      })
      return true
    } catch (err) {
      console.log('fetchTransferToVault error: ', err)
      throw thunkAPI.rejectWithValue('fetchTransferToVault didnt work')
    }
  }
)

interface fetchTradeProps extends Omit<BaseFetchProps, 'userAddress'> {
  userBlocks: number[]
  vaultBlocks: number[]
  donation: number
}

export const fetchTrade = createAsyncThunk(
  'blocks/fetchTradeStatus',
  async (
    {
      contract: tradableContract,
      userBlocks,
      vaultBlocks,
      donation,
    }: fetchTradeProps,
    thunkAPI
  ) => {
    if (!tradableContract) return false
    try {
      await tradableContract.trade(userBlocks, vaultBlocks, {
        value: ethers.utils.parseEther(donation.toString()),
      })
      return true
    } catch (err) {
      console.log('fetchTrade error: ', err)
      throw thunkAPI.rejectWithValue('fetchTrade didnt work')
    }
  }
)

interface fetchWithdrawProps extends Omit<BaseFetchProps, 'userAddress'> {
  blocks: number[]
  donation: number
}

export const fetchWithdraw = createAsyncThunk(
  'blocks/fetchWithdrawStatus',
  async (
    { contract: tradableContract, blocks, donation }: fetchWithdrawProps,
    thunkAPI
  ) => {
    if (!tradableContract) return false
    try {
      await tradableContract.withdrawNFTs(blocks, {
        value: ethers.utils.parseEther(donation.toString()),
      })
      return true
    } catch (err) {
      console.log('fetchWithdraw error: ', err)
      throw thunkAPI.rejectWithValue('fetchWithdraw didnt work')
    }
  }
)
