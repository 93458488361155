import { createSlice } from '@reduxjs/toolkit'
import {
  fetchBlocks,
  fetchIsAllowedToMove,
  fetchMoveApproval,
} from 'services/blocks'
import { STATUS } from 'utils/status'
export interface MyBlocksState {
  list: number[]
  moveIsApproved: boolean
  isAllowedToMove: boolean
  error: string | null | unknown
  loading: STATUS
  loadingMove: STATUS
  listUpdated: boolean
}

const initialState: MyBlocksState = {
  list: [],
  moveIsApproved: false,
  isAllowedToMove: false,
  error: null,
  loading: STATUS.IDLE,
  loadingMove: STATUS.IDLE,
  listUpdated: false,
}

export const myBlocksSlice = createSlice({
  name: 'myBlocks',
  initialState,
  reducers: {
    clear: (state) => {
      state.loading = STATUS.IDLE
      state.loadingMove = STATUS.IDLE
      state.error = null
      state.listUpdated = false
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchBlocks.pending, (state) => {
      state.loading = STATUS.PENDING
      state.listUpdated = false
    })
    builder.addCase(fetchBlocks.rejected, (state) => {
      state.loading = STATUS.ERROR
      state.listUpdated = false
    })
    builder.addCase(fetchBlocks.fulfilled, (state, action) => {
      if (
        state.loading !== STATUS.IDLE &&
        state.list.length > 0 &&
        state.list.length !== action.payload.length
      ) {
        state.listUpdated = true
      }
      state.list = action.payload
      state.loading = STATUS.SUCCESS
    })
    builder.addCase(fetchMoveApproval.fulfilled, (state, action) => {
      state.loadingMove = STATUS.PENDING
      state.moveIsApproved = action.payload
      state.error = null
    })
    builder.addCase(fetchMoveApproval.rejected, (state, action) => {
      state.error = action.payload
    })
    builder.addCase(fetchIsAllowedToMove.pending, (state) => {
      state.loadingMove = STATUS.PENDING
    })
    builder.addCase(fetchIsAllowedToMove.fulfilled, (state, action) => {
      state.isAllowedToMove = action.payload
      state.error = null
      state.loadingMove = STATUS.SUCCESS
    })
    builder.addCase(fetchIsAllowedToMove.rejected, (state, action) => {
      state.loadingMove = STATUS.ERROR
    })
  },
})

// Action creators are generated for each case reducer function
// export const {  } = myBlocksSlice.actions

export default myBlocksSlice.reducer
