import { useRef } from 'react'
import { HashLink } from 'react-router-hash-link'
import {
  Box,
  Button,
  Center,
  Flex,
  Portal,
  SimpleGrid,
  useBoolean,
  useFocusOnShow,
  VStack,
  useColorModeValue as mode,
  Heading,
} from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import { HTMLMotionProps, motion, Variants } from 'framer-motion'
import FocusLock from 'react-focus-lock'
import { HiOutlineChatAlt2, HiOutlineMenu, HiOutlineX } from 'react-icons/hi'
import { GoIssueOpened } from 'react-icons/go'
import { RemoveScroll } from 'react-remove-scroll'
import { FaHistory, FaQuestion } from 'react-icons/fa'
import { NavLink } from '../../atoms/NavLink'
import urls from 'base/paths'

const variants: Variants = {
  show: {
    display: 'revert',
    opacity: 1,
    scale: 1,
    transition: { duration: 0.2, ease: 'easeOut' },
  },
  hide: {
    opacity: 0,
    scale: 0.98,
    transition: { duration: 0.1, ease: 'easeIn' },
    transitionEnd: { display: 'none' },
  },
}

function scrollCustomFunction(el: HTMLElement) {
  const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset
  const yOffset = -90
  window.scrollTo({
    top: yCoordinate + yOffset,
    behavior: 'smooth',
  })
}
const Backdrop = ({ show }: { show?: boolean }) => (
  <Portal>
    <motion.div
      initial={false}
      animate={show ? 'show' : 'hide'}
      transition={{ duration: 0.1 }}
      variants={{
        show: { opacity: 1, display: 'revert' },
        hide: { opacity: 0, transitionEnd: { display: 'none' } },
      }}
      style={{
        width: '100%',
        top: 0,
        bottom: 0,
        position: 'absolute',
        background: 'rgba(0,0,0,0.2)',
        inset: 0,
        zIndex: 3,
      }}
    />
  </Portal>
)

const Transition = (props: HTMLMotionProps<'div'> & { in?: boolean }) => {
  const { in: inProp, ...rest } = props
  return (
    <motion.div
      {...rest}
      initial={false}
      variants={variants}
      animate={inProp ? 'show' : 'hide'}
      style={{
        transformOrigin: 'top right',
        position: 'absolute',
        width: 'calc(100% - 32px)',
        top: '24px',
        left: '16px',
        margin: '0 auto',
        zIndex: 1,
      }}
    />
  )
}

export const MobileNav = ({ balance = 0 }) => {
  const [show, { toggle, off }] = useBoolean()
  const ref = useRef<HTMLDivElement>(null)
  useFocusOnShow(ref, { visible: show, shouldFocus: true })

  return (
    <>
      <Box
        as="button"
        type="button"
        p="1"
        fontSize="2xl"
        color="gray.600"
        onClick={toggle}
        display={{ base: 'block', lg: 'none' }}
      >
        <HiOutlineMenu />
      </Box>

      <Transition in={show}>
        <RemoveScroll enabled={show}>
          <Backdrop show={show} />
        </RemoveScroll>
        <FocusLock disabled={!show} returnFocus>
          <Box
            bg={mode('white', 'gray.700')}
            shadow="lg"
            ref={ref}
            tabIndex={0}
            outline={0}
          >
            <Box pt="5" pb="6" px="5">
              <Flex justify="space-between" align="center">
                <Heading size={'mg'}>Trade Blocks</Heading>
                <Box mr="-2" mt="-2">
                  <Center
                    as="button"
                    type="button"
                    onClick={off}
                    p="1"
                    color={mode('gray.600', 'gray.400')}
                    _hover={{ bg: mode('gray.100', 'gray.600') }}
                  >
                    <Box srOnly>Close menu</Box>
                    <HiOutlineX aria-hidden fontSize="1.5rem" />
                  </Center>
                </Box>
              </Flex>
              <SimpleGrid as="nav" gap="6" mt="8" columns={{ base: 1, sm: 2 }}>
                <HashLink
                  to={urls.howItWorks}
                  scroll={scrollCustomFunction}
                  onClick={off}
                >
                  <NavLink.Mobile icon={FaQuestion}>
                    How does it works?
                  </NavLink.Mobile>
                </HashLink>
                <HashLink
                  to={urls.activities}
                  scroll={scrollCustomFunction}
                  onClick={off}
                >
                  <NavLink.Mobile icon={FaHistory}>Activities</NavLink.Mobile>
                </HashLink>
                <HashLink
                  to={urls.faq}
                  scroll={scrollCustomFunction}
                  onClick={off}
                >
                  <NavLink.Mobile icon={HiOutlineChatAlt2}>FAQ</NavLink.Mobile>
                </HashLink>
                <HashLink
                  to={urls.report}
                  scroll={scrollCustomFunction}
                  onClick={off}
                >
                  <NavLink.Mobile icon={GoIssueOpened}>
                    Report an Issue
                  </NavLink.Mobile>
                </HashLink>
              </SimpleGrid>
              <VStack mt="8" spacing="4">
                <Box textAlign="center" fontWeight="medium">
                  <Box
                    as="a"
                    color={mode('blue.600', 'blue.400')}
                    href={urls.deposit}
                  >
                    Deposit
                  </Box>
                </Box>
                <Box
                  textAlign="center"
                  fontWeight="medium"
                  display={balance > 0 ? 'block' : 'none'}
                >
                  <Box
                    as="a"
                    color={mode('blue.600', 'blue.400')}
                    href={urls.withdraw}
                  >
                    Withdraw
                  </Box>
                </Box>
                <Button w="full" colorScheme="brand" to={urls.trade} as={Link}>
                  Trade
                </Button>
              </VStack>
            </Box>
          </Box>
        </FocusLock>
      </Transition>
    </>
  )
}
