import env from 'env-var'

const envPrefix = 'REACT_APP_'
const buildEnvName = (value) => `${envPrefix}${value}`

export const ENV = env.get('NODE_ENV').default('development').asString()
export const PUBLIC_URL = `https://${env
  .get(buildEnvName('VERCEL_URL'))
  .default('')
  .asString()}/`
export const TRADABLE_CORE_ADDRESS = env
  .get(buildEnvName('TRADABLE_CORE_ADDRESS'))
  .required()
  .asString()

export const BLOCKS_CORE_ADDRESS = env
  .get(buildEnvName('BLOCKS_CORE_ADDRESS'))
  .required()
  .asString()

export const PROVIDER_KEY = env
  .get(buildEnvName('PROVIDER_KEY'))
  .required()
  .asString()

export const WEB3_NETWORK = env
  .get(buildEnvName('WEB3_NETWORK'))
  .default('local')
  .asString()

export const ETHERSCAN_URL = env
  .get(buildEnvName('ETHERSCAN_URL'))
  .required()
  .asUrlString()
export const OPENSEA_URL = env
  .get(buildEnvName('OPENSEA_URL'))
  .required()
  .asUrlString()

export const S3_URL = env.get(buildEnvName('S3_URL')).required().asUrlString()

export const BLOCKS_URL = `${S3_URL}blocks`
