import { Box, CloseButton, Container, Stack, Text } from '@chakra-ui/react'
import { Copyright } from './Copyright'
import { Logo } from 'components/atoms/Logo'
import { SocialMediaLinks } from './SocialMediaLinks'
import { useContext } from 'react'
import GlobalContext from 'contexts/GlobalContext'
import { useCookies } from 'react-cookie'

const cookieNoticeName = 'cookie-notice'
const cookieOptions = {
  sameSite: true,
  secure: true,
  maxAge: 2592000, // 30 days
}

export const Footer = () => {
  const [cookies, setCookie] = useCookies([cookieNoticeName])
  const { footerRef } = useContext(GlobalContext)
  return (
    <>
      <Box ref={footerRef} position="sticky" bottom="0" zIndex="4" />
      <Box
        as="footer"
        role="contentinfo"
        py="4"
        px={{ base: '4', md: '8' }}
        // bgColor={'black'}
        borderTop={'1px solid black'}
        borderTopColor={'brand.200'}
      >
        <Stack maxW="7xl" mx="auto">
          <Stack
            direction="row"
            spacing="4"
            align="center"
            justify="space-between"
            alignContent={'center'}
          >
            <Box>
              <Logo />
              <Copyright alignSelf={{ base: 'center', sm: 'start' }} mt={4} />
            </Box>
            <SocialMediaLinks />
          </Stack>
        </Stack>
      </Box>
      {!cookies[cookieNoticeName] && (
        <Box bg="blackAlpha.800" color="white" position="sticky" bottom={'0'}>
          <Container
            maxW="container.xl"
            py={{ base: '4', md: '3.5' }}
            px={'10'}
          >
            <Stack
              direction={{ base: 'column', md: 'row' }}
              justify="center"
              spacing={{ base: '0.5', md: '1.5' }}
              pe={{ base: '4', sm: '0' }}
            >
              <Text fontWeight="medium">
                This site is using third-party cookies to track issues and app
                behaviours using New Relic.
              </Text>
            </Stack>
            <CloseButton
              position="absolute"
              right="2"
              top="2.5"
              onClick={() => setCookie(cookieNoticeName, true, cookieOptions)}
            />
          </Container>
        </Box>
      )}
    </>
  )
}
