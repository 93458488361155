import { ExternalLinkIcon } from '@chakra-ui/icons'
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Heading,
  Link,
  Text,
} from '@chakra-ui/react'
import { OPENSEA_URL, TRADABLE_CORE_ADDRESS } from 'base/dotenv'

export const FAQ = () => {
  return (
    <Box my={40} id="faq">
      <Heading size="2xl" textAlign={'center'}>
        FAQ
      </Heading>
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        mx={'auto'}
        mt={'10'}
        maxW="container.lg"
      >
        <Accordion colorScheme={'brand'} allowToggle allowMultiple w={'100%'}>
          <AccordionItem>
            <AccordionButton>
              <Heading size="md" flex="1" textAlign="left" py="3">
                What is this tool about?
              </Heading>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={4}>
              This tool uses the official Blocks from The Panels NFT collection
              to help and improve the ability of community trade and build
              Panels. You can see more about this collection{' '}
              <Link
                href="https://thepanels.art/"
                isExternal
                textDecor={'underline'}
              >
                here <ExternalLinkIcon mx="1px" fontSize={'sm'} />
              </Link>
              .<br />I built this solution after see the difficulty of people
              trading or buying Blocks to build their Panels on{' '}
              <Link
                href="https://twitter.com/hashtag/panelscontest?src=hash&ref_src=twsrc%5Etfw%7Ctwcamp%5Etweetembed&ref_url=https%3A%2F%2Ffluff-nymphea-319.notion.site%2FAbout-Arod-56fd3200bd574e9ea6d5f5e78f9d6305"
                isExternal
                textDecor={'underline'}
              >
                #panelscontest <ExternalLinkIcon mx="1px" fontSize={'sm'} />
              </Link>
              . You can check more about me on{' '}
              <Link
                href="https://thearod.dev/"
                isExternal
                textDecor={'underline'}
              >
                my notion <ExternalLinkIcon mx="1px" fontSize={'sm'} />
              </Link>
              .
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionButton>
              <Heading size="md" flex="1" textAlign="left" py="3">
                Why do I pay 1 Block to Trade?
              </Heading>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={4}>
              <Text>
                The 1 Block fee incentivizes more people to deposit their Blocks
                from their wallets. They provide Blocks to be traded and get a
                percentage of each trade/deposit. Trades and Deposits have this
                fee.
              </Text>
              <br />
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <AccordionButton>
              <Heading size="md" flex="1" textAlign="left" py="3">
                Why do I pay 1 Block + ether amount to Deposit?
              </Heading>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={4}>
              <Text>
                The 1 Block fee is used to incentivize those who already
                deposited Blocks. <br />
                The ether fee will cover the project costs as the Smart Contract
                deployment, the spent developer hours, and support tools for
                other collections in the future.
              </Text>
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <AccordionButton>
              <Heading size="md" flex="1" textAlign="left" py="3">
                Why should I Deposit my Blocks in the Vault?
              </Heading>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={4}>
              <Text>
                You help the community, providing liquidity to Block trading.
                Also, it gives way to the community building unique Panels.
              </Text>
              <Text>
                You deposit the Blocks that you aren't using now and get a
                profit from each trade and deposit.
              </Text>
              <Text>
                You get a percentage based on your deposited Blocks for the
                total deposited Blocks in each trade or new deposit.
              </Text>
              <Text fontStyle={'italic'} fontWeight={'bold'} fontSize={'sm'}>
                Example: If you have 10 Blocks deposited and 20 in total, you
                get 10/20 from the following trade/deposit = 50%. You can
                withdraw it when it accumulates enough to get 1(100%) piece
                (*check the withdraw rules).
              </Text>
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <AccordionButton>
              <Heading size="md" flex="1" textAlign="left" py="3">
                Can I withdraw my Blocks partially?
              </Heading>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={4}>
              You can withdraw any amount of Blocks any time you want. As long
              you let at least 1 Block deposited.
              <br /> All withdraws are taken from your deposit balance
              (deposited Blocks). Therefore, it will never decrease your profit
              balance. However, you must let at least 1 deposited Block or
              withdraw all your Blocks at one time.
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <AccordionButton>
              <Heading size="md" flex="1" textAlign="left" py="3">
                What does happen with the Blocks that I deposited?
              </Heading>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={4}>
              They are moved to the Contract Wallet. Finally, you can see all
              deposited Blocks on{' '}
              <Link
                isExternal
                href={`${OPENSEA_URL}${TRADABLE_CORE_ADDRESS}`}
                textDecor={'underline'}
              >
                OpenSea
              </Link>
              . <br /> You will have a deposit balance on the contract when the
              transaction is confirmed. This balance is used when you want to
              withdraw the Blocks from the TB Vault.
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionButton>
              <Heading size="md" flex="1" textAlign="left" py="3">
                Why do I need to execute the setApprovalForAll before my
                trade/deposit? trade/deposit?
              </Heading>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={4}>
              When depositing or trading, the contract needs to move the Blocks
              from your wallet to the contract wallet. The Blocks are moved when
              you confirm a transfer action only, like trade or deposit. Don't
              worry. No Blocks are moved when confirming set approval
              transactions.
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionButton>
              <Heading size="md" flex="1" textAlign="left" py="3">
                Do I need to execute setApprovalForAll in all transactions?
              </Heading>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={4}>
              You only need to approve once, and it will be valid for the
              following trades or deposits.
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionButton>
              <Heading size="md" flex="1" textAlign="left" py="3">
                Why is the gas fee expensive sometimes?
              </Heading>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={4}>
              NFTs moving from one wallet to another are the core functionality
              of this tool. The more NFTs you move, the more expensive the
              transaction is, even though I could have written the contract with
              better gas optimization codes. We have limits on each tx for 25
              NFTs to avoid the risk of out-of-gas error. You only need to
              approve once, and it will be valid for the following trades or
              deposits.
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionButton>
              <Heading size="md" flex="1" textAlign="left" py="3">
                What happens if my trade executes after someone gets the same
                Blocks I selected?
              </Heading>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={4}>
              The transaction will fail. The rule is first come, first served.
              Don't forget the fees set on Metamask can make the transaction go
              first than others.
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionButton>
              <Heading size="md" flex="1" textAlign="left" py="3">
                Will my deposited Blocks be displayed in my Wallet?
              </Heading>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={4}>
              No, once Blocks are deposited, they are transferred to the
              Contract Wallet, and you start to have a balance inside the
              Contract.
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionButton>
              <Heading size="md" flex="1" textAlign="left" py="3">
                What does happen if my selected Block is not available when my
                transaction is to be executed?
              </Heading>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={4}>
              The transaction will fail. The rule is first come, first served.
              Don't forget the fees set on Metamask can make the transaction go
              first than others.
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionButton>
              <Heading size="md" flex="1" textAlign="left" py="3">
                Is there any risk involved with this tool?
              </Heading>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={4}>
              The contract was strongly tested by early adopters from the
              community and myself, trying to find bugs or mistakes.
              Unfortunately, as with all smart contracts and programming stuff,
              it can have unknown bugs. If the smart contract has a bug, it
              can't be fixed, and I have no right to move Blocks or modification
              rules already developed. It is the most considerable risk, and you
              should use this tool knowing the risk by yourself and taking it,
              as you may know how smart contracts technology works. <br />
              Any bug reported to me on Twitter (@TheArodEth) or Discord
              (Arod#0001), I'll be fixing it as soon as possible.
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionButton>
              <Heading size="md" flex="1" textAlign="left" py="3">
                Is this tool related to Arihz or Emergence DAO?
              </Heading>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={4}>
              No. Arod developed this tool. People from the community (who love
              The Panels collection and want to make it!) tested it. Arihz and
              Emergence DAO didn't know about the development of this tool until
              it was released and shared with the world (if they read something
              about it).
              <br />
              This tool uses the official Blocks from The Panels collection to
              help and improve the ability of community trade and build Panels.
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </Box>
    </Box>
  )
}
