import { Box, Text } from '@chakra-ui/react'

interface Props {
  value: number
  label: string
}

export const TimeUnit = (props: Props) => {
  const { value, label } = props
  return (
    <Box textAlign="center">
      <Text
        fontWeight="semibold"
        fontSize={{ base: 'md', md: 'xl' }}
        lineHeight="1"
      >
        {value.toLocaleString('en-US', { minimumIntegerDigits: 2 })}
      </Text>
      <Text fontSize="xs" color="gray.400">
        {label}
      </Text>
    </Box>
  )
}
