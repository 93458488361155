const urls = {
  home: '/',
  deposit: '/deposit/',
  withdraw: '/withdraw/',
  trade: '/trade/',
  mint: '/mint/',
  howItWorks: '/#howItWorks',
  activities: '/#activities',
  faq: '/#faq',
  report: '/#report',
}

export default urls
