import {
  Box,
  useColorModeValue,
  Stack,
  Icon,
  Text,
  useBoolean,
} from '@chakra-ui/react'
import { VscChromeClose } from 'react-icons/vsc'
import { Timer } from 'components/molecules/Timer/Timer'

export const Countdown = () => {
  const [isOpened, setIsOpened] = useBoolean(true)
  return (
    <Box as="section" hidden={!isOpened}>
      <Box
        bg={useColorModeValue('black', 'gray.700')}
        color="white"
        position="relative"
      >
        <Box
          maxW="7xl"
          mx="auto"
          px={{ base: '4', md: '8', lg: '12' }}
          py={{ base: '2', md: '2' }}
        >
          <Stack
            direction={{ base: 'row', md: 'row' }}
            align="center"
            justify={{ base: 'space-between', md: 'center' }}
            spacing={{ base: '2', md: '20', lg: '7.5rem' }}
          >
            <Text fontWeight="medium" fontSize={{ base: 'xs', md: 'md' }}>
              Build Panels ends in
            </Text>
            <Timer />
            <Box
              as="button"
              aria-label="Close banner"
              position="absolute"
              right={{ base: '2', md: '4', lg: '6' }}
              top={{ base: '0', md: 'unset' }}
              onClick={setIsOpened.off}
            >
              <Icon as={VscChromeClose} boxSize={{ base: '5', md: '6' }} />
            </Box>
          </Stack>
        </Box>
      </Box>
    </Box>
  )
}
