import * as React from 'react'
import { hydrate, render } from 'react-dom'
import { Provider } from 'react-redux'
import theme from 'base/theme'
import { ColorModeScript } from '@chakra-ui/react'
import { store } from 'store'
import { App } from './components/pages/App'
import reportWebVitals from './reportWebVitals'
import * as serviceWorker from './serviceWorker'

const rootElement = document.getElementById('root')
if (rootElement?.hasChildNodes()) {
  hydrate(
    <React.StrictMode>
      <ColorModeScript initialColorMode={theme.config.initialColorMode} />
      <Provider store={store}>
        <App />
      </Provider>
    </React.StrictMode>,
    rootElement
  )
} else {
  render(
    <React.StrictMode>
      <ColorModeScript initialColorMode={theme.config.initialColorMode} />
      <Provider store={store}>
        <App />
      </Provider>
    </React.StrictMode>,
    rootElement
  )
}

// ReactDOM.render(
//   <React.StrictMode>
//     <ColorModeScript initialColorMode={theme.config.initialColorMode} />
//     <Provider store={store}>
//       <App />
//     </Provider>
//   </React.StrictMode>,
//   document.getElementById('root')
// )

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
