import { ReactNode, useEffect, useMemo, useRef, useState } from 'react'
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Divider,
  Drawer,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Heading,
  HStack,
  Link,
  Slider,
  SliderFilledTrack,
  SliderMark,
  SliderThumb,
  SliderTrack,
  Stack,
  Text,
  Tooltip,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react'
import { HiOutlineExclamation } from 'react-icons/hi'
import { CartItem } from 'components/molecules/CartItem'
import { BLOCKS_URL } from 'base/dotenv'
import { HStackItemList } from 'components/atoms/HStackItemList'
import { STATUS } from 'utils/status'

type CartDrawerProps = {
  blocksUser: number[]
  blocksVault?: number[]
  isOpened: boolean
  onClose: Function
  onRemove?: Function
  onConfirm: Function
  // onConfirm: MouseEventHandler<HTMLButtonElement>
  status: STATUS
  isButtonDisabled?: boolean
  title?: string | ReactNode
  feeText?: string | ReactNode
  confirmButtonText?: string
  dialogText?: ReactNode
}

export const CartDrawer = ({
  onClose,
  onConfirm,
  onRemove,
  isOpened,
  blocksUser,
  blocksVault = [],
  status,
  isButtonDisabled,
  title,
  feeText,
  dialogText,
  confirmButtonText,
}: CartDrawerProps) => {
  const [sliderValue, setSliderValue] = useState(0)
  const [showTooltip, setShowTooltip] = useState(false)
  const {
    isOpen: isOpenDialog,
    onOpen: onOpenDialog,
    onClose: onCloseDialog,
  } = useDisclosure()
  const cancelRef = useRef()
  const hasBlocksVault = blocksVault.length > 0
  const confirmText = useMemo(() => {
    if (confirmButtonText) return confirmButtonText
    return `Confirm ${hasBlocksVault ? 'trade' : 'deposit'}`
  }, [confirmButtonText, hasBlocksVault])

  useEffect(() => {
    if (!isOpened && isOpenDialog) onCloseDialog()
  }, [isOpenDialog, isOpened, onClose, onCloseDialog])

  return (
    <>
      <Drawer
        isOpen={isOpened}
        onClose={() => onClose()}
        size="md"
        /*`trapFocus` and `blockScrollOnMount` are only switched off so that the preview works properly. */
        blockScrollOnMount={false}
        trapFocus={false}
      >
        <DrawerOverlay />
        <DrawerContent
          bg={useColorModeValue('white', 'gray.800')}
          overflowY="auto"
        >
          <DrawerCloseButton
            size="lg"
            right={{ base: '4', md: '8' }}
            top="4"
            bg="inherit"
          />
          <Stack
            padding={{ base: '6', md: '10' }}
            height="full"
            spacing="8"
            overflowY="auto"
          >
            {typeof title === 'string' && <Heading size="md">{title}</Heading>}
            {typeof title !== 'string' && title}

            <Stack spacing={{ base: '4', md: '5' }}>
              {blocksUser.map((blockId) => (
                <CartItem
                  key={blockId}
                  name={`Block #${blockId}`}
                  imageUrl={`${BLOCKS_URL}/${blockId}_ps4.png`}
                  onClickDelete={onRemove ? onRemove(blockId) : undefined}
                />
              ))}
            </Stack>
            {hasBlocksVault && (
              <>
                <Divider size={'lg'} borderColor={'brand.700'} />
                <Heading size="sm">Blocks requested</Heading>
                <Stack spacing={{ base: '4', md: '5' }}>
                  {blocksVault.map((blockId) => (
                    <CartItem
                      key={blockId}
                      name={`Block #${blockId}`}
                      imageUrl={`${BLOCKS_URL}/${blockId}_ps4.png`}
                    />
                  ))}
                </Stack>
              </>
            )}
          </Stack>
          <Stack
            borderTopWidth="1px"
            px={{ base: '6', md: '10' }}
            py="4"
            spacing="5"
          >
            <Stack>
              {/* <HStack fontSize="sm" fontWeight="semibold">
                <Text flex="1">Gas estimative:</Text>
                <Text>0.0325841Ξ</Text>
              </HStack> */}
              {feeText && (
                <HStack fontSize="xl" fontWeight="semibold">
                  <Text flex="1">Fee:</Text>
                  <Text>{feeText}</Text>
                </HStack>
              )}
            </Stack>
            <Button
              colorScheme="brand"
              size="lg"
              fontSize="md"
              onClick={onOpenDialog}
              disabled={isButtonDisabled}
            >
              Continue
            </Button>
          </Stack>
        </DrawerContent>
      </Drawer>
      <AlertDialog
        isOpen={isOpened && isOpenDialog}
        /* @ts-ignore-next-line */
        leastDestructiveRef={cancelRef}
        onClose={onCloseDialog}
        size={'2xl'}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Confirm action
            </AlertDialogHeader>

            <AlertDialogBody>
              <Stack px={3} py="4" spacing="6">
                <Stack>
                  {dialogText}
                  <HStackItemList>
                    The contract was vigorously tested by early adopters from
                    the community and myself. However, as with all smart
                    contracts, it may have unknown bugs. Smart Contract issues
                    can't be fixed if they have a bug, and I have no right to
                    move Blocks or change the already deployed rules. Therefore,
                    it is the most considerable risk, and you should use this
                    tool knowing and taking the risk yourself.
                  </HStackItemList>
                  <HStackItemList>
                    Questions? Ask Arod on{' '}
                    <Link
                      isExternal
                      href="https://twitter.com/TheArodEth"
                      textDecor={'underline'}
                    >
                      Twitter
                    </Link>{' '}
                    or{' '}
                    <Link
                      isExternal
                      href="https://github.com/arodundef/arod/discussions/3"
                      textDecor={'underline'}
                    >
                      GitHub
                    </Link>
                    .
                  </HStackItemList>
                  <HStackItemList
                    icon={HiOutlineExclamation}
                    color={useColorModeValue('red.600', 'red.400')}
                  >
                    By clicking in{' '}
                    <Text fontWeight={'bold'} as="span">
                      confirm
                    </Text>
                    , you agree to all risks involved in the Smart Contract and
                    Website, which can have issues or bugs not found. It can
                    cause losses.
                  </HStackItemList>
                </Stack>
              </Stack>
              <Divider my={5} />
              <Box mx={2}>
                <Text fontWeight="bold" fontSize="lg" mb={3}>
                  Want to support more projects like this?
                </Text>
                <Text>
                  Consider a donation with this transaction to save Gas.
                  <br />
                  Slide below how much you want to donate, and we will include
                  this on your transaction.
                </Text>
                <Box mx={2}>
                  <Slider
                    aria-label="slider-ex-6"
                    onChange={(val) => setSliderValue(val)}
                    defaultValue={0}
                    min={0}
                    max={10}
                    step={0.01}
                    mt={5}
                    onMouseEnter={() => setShowTooltip(true)}
                    onMouseLeave={() => setShowTooltip(false)}
                    colorScheme="brand"
                  >
                    <SliderMark value={1} mt="1" ml="-2.5" fontSize="sm">
                      1
                    </SliderMark>
                    <SliderMark value={5} mt="1" ml="-2.5" fontSize="sm">
                      5
                    </SliderMark>
                    <SliderMark value={10} mt="1" ml="-2.5" fontSize="sm">
                      10
                    </SliderMark>
                    <SliderTrack>
                      <SliderFilledTrack />
                    </SliderTrack>
                    <Tooltip
                      hasArrow
                      bg="brand.500"
                      color="white"
                      placement="top"
                      isOpen={showTooltip}
                      label={`${sliderValue}Ξ`}
                    >
                      <SliderThumb bgColor={'brand.400'} />
                    </Tooltip>
                  </Slider>

                  {sliderValue > 0 && (
                    <Text
                      mt={5}
                      fontWeight={'bold'}
                      fontStyle={'italic'}
                      fontSize={'sm'}
                    >
                      Thanks for your donation! If you feel comfortable, let me
                      a DM or mention on Twitter to thank you properly. WGMI
                      ❤️😬👏🏻
                    </Text>
                  )}
                </Box>
              </Box>
            </AlertDialogBody>

            <AlertDialogFooter>
              {/* @ts-ignore-next-line */}
              <Button ref={cancelRef} onClick={onCloseDialog}>
                Cancel
              </Button>
              <Button
                colorScheme="brand"
                onClick={onConfirm(sliderValue)}
                ml={3}
                disabled={status === STATUS.PENDING}
              >
                {confirmText}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}
