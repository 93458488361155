import {
  ButtonGroup,
  ButtonGroupProps,
  IconButton,
  Tooltip,
} from '@chakra-ui/react'
import { FaGithub, FaTwitter, FaRegFileCode } from 'react-icons/fa'

export const SocialMediaLinks = (props: ButtonGroupProps) => (
  <ButtonGroup variant="ghost" color="gray.600" {...props}>
    <Tooltip label="Smart Contract code" fontSize="md">
      <IconButton
        as="a"
        href="https://etherscan.io/address/0xaf06e5080d5740d0430643aE6944eeaBf4F8b43B#code"
        aria-label="Smart Contract"
        icon={<FaRegFileCode fontSize="20px" />}
        target={'_blank'}
      />
    </Tooltip>
    <IconButton
      as="a"
      href="https://github.com/arodundef/arod/discussions/3"
      aria-label="GitHub"
      icon={<FaGithub fontSize="20px" />}
      target={'_blank'}
    />
    <IconButton
      as="a"
      href="https://twitter.com/TheArodEth"
      aria-label="Twitter"
      icon={<FaTwitter fontSize="20px" />}
      target={'_blank'}
    />
  </ButtonGroup>
)
