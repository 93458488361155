import { TRADABLE_CORE_ADDRESS } from 'base/dotenv'
import EthersContext from 'contexts/EtherInstances'
import { useCallback, useContext, useEffect, useRef } from 'react'
import {
  fetchBlocks,
  fetchIsAllowedToMove,
  fetchVaultBlocks,
} from 'services/blocks'
import { useAppDispatch, useAppSelector } from './redux'

const DEFAULT_TIMER = 10000

export const useRefreshBlocks = (
  refreshVault = true,
  refreshMyBlocks = true
) => {
  const { blocksContractSigned, userAddress, blocksContractRead } =
    useContext(EthersContext)
  const isAllowedToMove = useAppSelector(
    (state) => state.myBlocks.isAllowedToMove
  )
  const dispatch = useAppDispatch()
  const timer = useRef<undefined | NodeJS.Timeout>(undefined)

  const loadVaultBlocks = useCallback(() => {
    if (!isAllowedToMove) {
      dispatch(
        fetchIsAllowedToMove({
          contract: blocksContractRead,
          userAddress,
        })
      )
    }
    if (refreshVault && isAllowedToMove) {
      dispatch(
        fetchVaultBlocks({
          contract: blocksContractSigned,
          userAddress: TRADABLE_CORE_ADDRESS,
        })
      )
    }
    if (refreshMyBlocks && isAllowedToMove) {
      dispatch(
        fetchBlocks({
          contract: blocksContractSigned,
          userAddress,
        })
      )
    }
  }, [
    blocksContractRead,
    blocksContractSigned,
    dispatch,
    isAllowedToMove,
    refreshMyBlocks,
    refreshVault,
    userAddress,
  ])

  useEffect(() => {
    timer.current = setInterval(() => loadVaultBlocks(), DEFAULT_TIMER)
    return () => {
      if (typeof timer.current !== 'undefined') {
        clearInterval(timer.current)
      }
    }
  }, [loadVaultBlocks])

  return [loadVaultBlocks]
}
