import { useInterval } from '@chakra-ui/react'
import { useCallback, useState } from 'react'

interface Props {
  expiresInSeconds: number
}

// For more sophisticated timer hooks checkout https://github.com/amrlabib/react-timer-hook
export const useTimer = (props: Props) => {
  const { expiresInSeconds } = props
  const [planSeconds, setPlanSeconds] = useState(expiresInSeconds)
  const [seconds, setSeconds] = useState(getSecondsFromExpiry(expiresInSeconds))

  useInterval(() => setSeconds(getSecondsFromExpiry(planSeconds)), 1000)

  const setNewExpire = useCallback((_expiresInSeconds: number) => {
    setPlanSeconds(_expiresInSeconds)
  }, [])

  return {
    seconds: Math.floor(seconds % 60),
    minutes: Math.floor((seconds % (60 * 60)) / 60),
    hours: Math.floor((seconds % (60 * 60 * 24)) / (60 * 60)),
    days: Math.floor(seconds / (60 * 60 * 24)),
    setNewExpire,
  }
}

const getSecondsFromExpiry = (expire: number) =>
  Math.round((expire - new Date().getTime()) / 1000)
