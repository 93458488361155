import {
  BrowserRouter,
  HashRouter,
  Navigate,
  Route,
  Routes,
} from 'react-router-dom'
import { ChakraProvider, Box, useColorMode } from '@chakra-ui/react'
import { NavBar } from '../molecules/Navbar'
import theme from '../../base/theme'
import { Footer } from '../organisms/Footer'
import { Home } from 'components/pages/Home'
import { useEffect, ReactNode } from 'react'
import { Deposit } from 'components/pages/Deposit'
import urls from 'base/paths'
import { GlobalContextProvider } from 'contexts/GlobalContext'
import { ENV } from 'base/dotenv'
import { Trade } from './Trade'
import { EthersContextProvider } from 'contexts/EtherInstances'
import { MintButton } from 'components/molecules/MintButton'
import { Withdraw } from './Withdraw'

function ForceLightMode(props: { children: JSX.Element }) {
  const { colorMode, toggleColorMode } = useColorMode()

  useEffect(() => {
    if (colorMode === 'light') return
    toggleColorMode()
  }, [colorMode, toggleColorMode])

  return props.children
}

function Router({ children }: { children: ReactNode }) {
  if (ENV === 'production') return <BrowserRouter>{children}</BrowserRouter>
  return <HashRouter>{children}</HashRouter>
}

export const App = () => (
  <ChakraProvider theme={theme}>
    <GlobalContextProvider>
      <EthersContextProvider>
        <ForceLightMode>
          <Router>
            <Box>
              <NavBar />
              <Box minH="84vh">
                <Routes>
                  <Route
                    path="/:url/*"
                    children={() => (props: { location: { pathname: any } }) =>
                      <Navigate to={`${props.location.pathname}/`} />}
                  />
                  <Route path={urls.home} element={<Home />} />
                  <Route path={urls.deposit} element={<Deposit />} />
                  <Route path={urls.trade} element={<Trade />} />
                  <Route path={urls.withdraw} element={<Withdraw />} />
                  <Route path={urls.mint} element={<MintButton />} />
                  <Route path={'*'} element={<Home />} />
                </Routes>
              </Box>
              <Footer />
            </Box>
          </Router>
        </ForceLightMode>
      </EthersContextProvider>
    </GlobalContextProvider>
  </ChakraProvider>
)
